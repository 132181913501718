@font-face {
    font-family: "Apple SD Gothic Neo";
    src: url('./Assets/fonts/apple_sd_gothic_neo_r.ttf');
}

body {
  margin: auto;
  font-family: "Appe SD Gothic Neo";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  background: linear-gradient(180deg, #FFF 0%, #E5F7FF 100%);
  width: 100%;
  overflow-x: hidden;
  max-width: 480px;
}
code {
  font-family: "Appe SD Gothic Neo";
}
