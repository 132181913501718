:root {
    --black1: #222222;
    --black2: #444444;
    --black3: #636363;
    --blue1: #00A1E7;
    --blue2: #76A6BB;
    --blue3: #E4EDF1;
    --blue4: #A8CFE0;
    --blue5: #F3F7FF;
    --blue6: #DFE9EA;
    --blue7: #22B7F8;
    --gray1: #B4B7C4;
    --gray2: #E3E3E3;
    --gray3: #F5F5F5;
    --gray4: #F4F4F5;
    --red1: #FF4646;
    --white1: #FFFFFF;
    --white2: #F1F1F1;
    --yellow1: #FEE500;
    --yellow2: #FEEDB3
}